import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'

class CustomTextField extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
  }

  render() {
    const {
      input: { ...inputProps },
      meta: { touched, error, invalid } = {},
      ...props
    } = this.props
    return (
      <TextField
        error={touched && invalid}
        helperText={error}
        {...inputProps}
        {...props}
        fullWidth
      />
    )
  }
}

const styles = theme => ({})

export default withStyles(styles)(CustomTextField)
